<template>
  <section v-if="errored">
    <p>Sorry er is iets mis gegaan met het laden van deze pagina...</p>
  </section>
  <section v-else>
    <b-row>
      <b-col class="px-sm-0">
        <div
          v-if="postsLoading || categoriesLoading"
          class="d-flex flex-column"
        >
          <skeleton-box height="20px" width="31%" class="mt-1" />
          <div v-for="index in 2" :key="index">
            <div class="d-flex flex-column align-items-center p-3">
              <skeleton-box height="38px" width="60%" class="mt-3" />
              <skeleton-box height="20px" width="55%" class="mt-2" />
              <skeleton-box height="310px" width="100%" class="mt-2" />
            </div>
            <div class="d-flex flex-column px-3">
              <skeleton-box height="20px" />
              <skeleton-box height="20px" class="mt-2" />
              <skeleton-box height="20px" class="mt-3" />
              <skeleton-box height="20px" class="mt-2" />
              <skeleton-box height="20px" width="15%" class="mt-3 mb-5" />
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            {{ $t('message.blog.category') }}
            <span v-html="categories[selectedCatIdx].name"></span>
          </div>
          <b-card
            v-for="(post, index) in posts"
            :key="index"
            role="main"
            no-body
            tag="article"
            :id="post.slug"
            class="mb-3 border-0 wp-content"
          >
            <b-card-body>
              <h1 v-html="post.title.rendered" class="pt-3 text-center"></h1>
              <p
                v-if="post.status != 'publish'"
                class="text-center alert alert-primary"
                role="alert"
              >
                -- Status: {{ post.status }} --
              </p>
              <b-card-sub-title class="mb-3 text-center" sub-title-tag="p">
                {{ post.date_gmt | moment('timezone', timezone, 'LLLL') }}
              </b-card-sub-title>
              <b-card-text
                v-html="$options.filters.intro(post.content.rendered)"
              ></b-card-text>
              <more-link
                v-if="hasReadmore(post)"
                :catId="categories[selectedCatIdx].slug"
                :postId="post.slug"
              />
              <more-link
                v-else
                :catId="categories[selectedCatIdx].slug"
                :postId="post.slug"
                variant="comments"
              />
            </b-card-body>
          </b-card>
        </div>
      </b-col>

      <!-- <b-col md="4" class="pr-sm-0 sidebar"> -->
      <b-col md="4" class="sidebar">
        <nav>
          <ul class="nav flex-column">
            <li
              v-for="(category, index) in categories"
              :key="index"
              class="nav-item"
            >
              <h1 class="pt-3">
                <b-link
                  class="cat-entry"
                  :to="{ name: 'blog-cat', params: { catId: category.slug } }"
                >
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                    class="nav-arrow"
                  />
                  <span class="pl-2" v-html="category.name"></span>
                </b-link>
              </h1>
              <div v-if="category.id == selectedCatId">
                <div v-if="postsLoading">Loading...</div>
                <ul v-else class="pl-0">
                  <li
                    v-for="(post, index) in posts"
                    :key="index"
                    class="toc-entry"
                  >
                    <b-link
                      class="cat-entry"
                      :to="{
                        name: 'post',
                        params: { catId: catId, postId: post.slug },
                      }"
                    >
                      <span class="pl-2" v-html="post.title.rendered"></span>
                    </b-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
        <aside class="mt-3">
          <h1 class="mb-3">{{ $t('message.subscription.title') }}</h1>
          <span class="d-block mb-1">
            {{ $t('message.subscription.intro') }}
          </span>
          <subscribe-form />
        </aside>
      </b-col>
    </b-row>
  </section>
</template>

<script>
//import "../plugins/scrollto";
import MoreLink from '@/components/MoreLink.vue'
import SkeletonBox from '../components/SkeletonBox'
import SubscribeForm from '../components/SubscribeForm'
import { WpService } from '../services/wp.service'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BCardBody,
  BCardText,
  BCardSubTitle,
} from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons'
library.add(faAngleRight, faArrowRight)

export default {
  name: 'BlogList',
  components: {
    MoreLink,
    SkeletonBox,
    SubscribeForm,
    'b-row': BRow,
    'b-col': BCol,
    'b-link': BLink,
    'b-card': BCard,
    'b-card-body': BCardBody,
    'b-card-text': BCardText,
    'b-card-sub-title': BCardSubTitle,
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    catId: String,
  },
  data() {
    return {
      selectedCatIdx: -1,
      categories: null,
      categoriesLoading: true,
      posts: null,
      postsMoreLink: [],
      postsLoading: true,
      errored: false,
      //scrollToId: null
    }
  },
  computed: {
    ...mapState(['lang']),
    timezone: function () {
      return moment.tz.guess()
    },
    selectedCatId: function () {
      var result =
        this.categories &&
        this.categories[this.selectedCatIdx] &&
        this.categories[this.selectedCatIdx].id
      return result
    },
  },
  watch: {
    catId: function () {
      // console.log("watch catId: ", this.catId);
      this.selectCategory()
    },
    lang: function () {
      // console.log("watch lang");
      this.getCategories()
    },
    categories: function () {
      // console.log("watch categories");
      this.selectCategory()
    },
  },
  methods: {
    selectCategory() {
      // console.log("selectCategory - going to select: ", this.catId);
      if (this.categories) {
        this.selectedCatIdx = this.categories.findIndex(
          (x) => x.id == this.catId || x.slug == this.catId
        )
        // console.log("selectCategory - index ", this.selectedCatIdx);
        if (this.selectedCatIdx === -1) {
          this.$router.push({
            name: 'blog',
            params: { catId: this.categories[0].slug },
          })
        } else {
          this.getPostsForCategory()
        }
      }
    },
    getCategories() {
      this.categoriesLoading = true
      WpService.categories(this.lang)
        .then((response) => {
          this.categories = response
        })
        .catch(() => {
          this.errored = true
        })
        .finally(() => {
          this.categoriesLoading = false
        })
    },
    getPostsForCategory() {
      this.postsLoading = true
      WpService.posts(this.lang, this.selectedCatId)
        .then((response) => {
          this.posts = response
        })
        .catch(() => {
          this.errored = true
        })
        .finally(() => {
          this.postsLoading = false
        })
    },
    hasReadmore(post) {
      if (!post || !post.content || !post.content.rendered) return false
      var res = post.content.rendered.toString().indexOf('<!--more-->') !== -1
      return res
    },
  },
  mounted() {
    this.getCategories()
    this.selectCategory()
  },
  filters: {
    intro: function (value) {
      if (!value) return ''
      var res = value.toString().split('<!--more-->', 2)
      return res.length > 1 ? res[0] : value
    },
  },
}
</script>

<style scoped lang="scss">
.sidebar {
  background-color: $blog-item-sidebar-background-color;
}

// .sidebar-button {
//   color: $sidebar-button-color;
//   font-weight: 500;
// }

.cat-entry {
  &:hover {
    text-decoration: none;
  }

  .nav-arrow {
    transition: transform 100ms ease-in-out;
  }

  &.router-link-active,
  &.router-link-exact-active {
    cursor: default;

    .nav-arrow {
      transform: rotate(90deg);
    }
  }
}

.toc-entry {
  display: block;
  a:hover {
    text-decoration: none;
  }
}
</style>
