<template>
  <b-link
    :to="{
      name: 'post',
      params: { catId: catId, postId: postId },
    }"
    class="type-blog-link"
  >
    {{ label }}
    <font-awesome-icon :icon="['fas', 'arrow-right']" />
  </b-link>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'MoreLink',
  components: {
    'b-link': BLink,
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    catId: String,
    postId: String,
    variant: String,
  },
  computed: {
    label() {
      if (this.variant == 'comments') {
        return this.$t('message.blog.linkComments')
      }
      return this.$t('message.blog.linkMore')
    },
  },
}
</script>

<style scoped lang="scss"></style>
