import { ApiService, ApiError } from './api.service'

const EmailService = {
  postSubscription: async function (form) {
    const resource = '/wordpress/wp-json/wp/v2/email/subscribe'

    var formData = new FormData()
    formData.set('contact_data[first_name]', form.name)
    formData.set('contact_data[last_name]', '')
    formData.set('contact_data[email]', form.email)
    formData.set('contact_data[lists][]', 2)
    formData.set('contact_data[send_welcome_email]', 'on')

    try {
      const response = await ApiService.post(resource, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },
}

export default EmailService

export { EmailService, ApiError }
