<template>
  <div>
    <b-form @submit="onSubmitSubscription" v-if="!submitted && !errored">
      <b-form-input
        id="input-1"
        v-model="form.name"
        required
        v-bind:placeholder="$t('message.subscription.name')"
        class="rounded mb-1"
      ></b-form-input>
      <b-form-input
        id="input-2"
        v-model="form.email"
        type="email"
        required
        v-bind:placeholder="$t('message.subscription.email')"
        class="rounded mb-3"
      ></b-form-input>
      <b-button
        type="submit"
        variant="secondary"
        class="mb-3 sidebar-button"
        :disabled="submitting"
      >
        <b-spinner v-if="submitting" small></b-spinner>
        {{ $t('message.subscription.submit') }}
      </b-button>
    </b-form>
    <div v-if="submitted" class="text-secondary">
      {{ $t('message.subscription.submitted') }}
    </div>
    <div v-if="errored" class="text-danger">
      {{ $t('message.subscription.error') }}
    </div>
  </div>
</template>

<script>
import { EmailService } from '../services/email.service'
import { BButton, BForm, BFormInput, BSpinner } from 'bootstrap-vue'

export default {
  name: 'SubscribeForm',
  components: {
    'b-button': BButton,
    'b-spinner': BSpinner,
    'b-form': BForm,
    'b-form-input': BFormInput,
  },
  data() {
    return {
      errored: false,
      form: {
        name: '',
        email: '',
        message: '',
      },
      submitting: false,
      submitted: false,
    }
  },
  methods: {
    onSubmitSubscription(evt) {
      evt.preventDefault()
      this.$ga.event('subscription', 'submit form')
      this.submitting = true
      EmailService.postSubscription(this.form)
        .then(() => {
          this.submitted = true
        })
        .catch(() => {
          this.errored = true
        })
        .finally(() => (this.submitting = false))
    },
  },
}
</script>

<style scoped lang="scss">
.contact-button {
  color: $contact-button-color;
  font-weight: 500;
}
</style>
